import React from 'react';
import { IconName } from '@src/components/atoms/hero-icon';
import HeroIcon from '@components/atoms/hero-icon';
import style from './style.module.css';

interface PainVsFeatureItemProps {
  icon: IconName;
  title: string;
  text: string;
}

export const PainVsFeatureItem: React.FC<PainVsFeatureItemProps> = ({ icon, title, text }) => {
  return (
    <div className={style.painVsFeatureItem}>
      <div className={style.painVsFeatureItemHeader}>
        <div className={style.painVsFeatureItemIcon}>
          <HeroIcon icon={icon} type="solid" extraClassNames="text-brand-primary-70" />
        </div>
        <div className={style.painVsFeatureItemTitleContainer}>
          <div className={style.painVsFeatureItemTitle}>{title}</div>
        </div>
      </div>
      <div className={style.painVsFeatureItemText}>{text}</div>
    </div>
  );
};
