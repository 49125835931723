import React, { useState, useRef, KeyboardEvent, useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroIcon from '@components/atoms/hero-icon';

import style from './style.module.css';

interface SingleVideoSectionProps {
  videoPath: string;
  videoTitle: string;
  title: string;
  description: string;
  descriptionMaxWidth: string;
  captionPath?: string;
  thumbnailPath?: string;
  uploadDate?: string;
  duration?: string;
  interactionCount?: string;
  children?: React.ReactNode;
}

const SingleVideoSection: React.FC<SingleVideoSectionProps> = ({
  videoPath,
  videoTitle,
  title,
  description,
  descriptionMaxWidth,
  captionPath,
  thumbnailPath,
  uploadDate = '2024-01-01',
  duration = 'PT2M30S',
  interactionCount = '12345',
  children,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const isSmallScreen = useScreenSize();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      setIsVideoPlaying(false);
    }
  };

  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      handlePlayClick();
    }
  };

  const schemaMarkup = useMemo(
    () => ({
      '@context': 'http://schema.org',
      '@type': 'VideoObject',
      name: videoTitle,
      description: description,
      thumbnailUrl: thumbnailPath,
      uploadDate: uploadDate,
      duration: duration,
      contentUrl: videoPath,
      embedUrl: 'https://clarke.com.br/',
      interactionCount: interactionCount,
    }),
    [videoTitle, description, thumbnailPath, uploadDate, duration, videoPath, interactionCount],
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(schemaMarkup);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [schemaMarkup]);

  const videoSectionClass = twMerge(isSmallScreen ? 'py-10' : 'py-20', style.VideoSection);
  const videoContainerClass = twMerge(isSmallScreen ? 'px-4' : 'px-40', style.VideoContainer);
  const videoDescriptionClass = twMerge(
    isSmallScreen ? 'text-paragraph-medium' : 'text-heading-xSmall',
    style.VideoDescription,
  );
  const videoCheckPLayer = twMerge('top-0 left-0 w-full h-full rounded-medium', isVideoPlaying ? 'block' : 'hidden');
  const btnVideoPlay = twMerge(isSmallScreen ? 'w-[4rem] h-[4rem]' : 'w-[5.4375rem] h-[5.4375rem]', style.btnVideoPlay);

  return (
    <section className={videoSectionClass} role="region" aria-labelledby="video-section-title">
      <div className={videoContainerClass}>
        <div className={style.VideoContent}>
          <div>
            <div className="relative">
              {!isVideoPlaying && thumbnailPath && (
                <div className="relative w-full h-full">
                  <img
                    className="w-full h-full rounded-medium"
                    src={thumbnailPath}
                    alt={`Miniatura do vídeo intitulado '${videoTitle}'`}
                    onClick={handlePlayClick}
                    role="button"
                    tabIndex={0}
                    loading="lazy"
                    decoding="async"
                    aria-label={`Iniciar video: ${videoTitle}`}
                    onKeyDown={handleKeyDown}
                  />
                  <div
                    role="button"
                    className={twMerge(style.primaryCustomButtonClasses, style.standardBtnCustom, btnVideoPlay)}
                    aria-label={`Play ${videoTitle}`}
                    onClick={handlePlayClick}
                  >
                    <HeroIcon type="solid" icon="PlayIcon" extraClassNames="w-10 h-10" />
                  </div>
                </div>
              )}
              <video
                ref={videoRef}
                className={videoCheckPLayer}
                src={videoPath}
                title={videoTitle}
                controlsList="nodownload"
                controls
                onEnded={handleVideoEnd}
                aria-describedby="video-section-description"
              >
                {captionPath && <track kind="subtitles" srcLang="pt-br" src={captionPath} default />}
                Seu navegador não suporta o elemento de vídeo.
              </video>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <h2 id="video-section-title" className={style.VideoTitle}>
            {title}
          </h2>
          <p className={twMerge(descriptionMaxWidth, videoDescriptionClass)} id="video-section-description">
            {description}
          </p>
          {children}
        </div>
      </div>
    </section>
  );
};

export default SingleVideoSection;
