import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroSection from '@components/molecules/hero-section';
import OurSolutionsSection from '@components/molecules/our-solutions-section';
import ClarkeServicesSection from '@components/molecules/clarke-services-section';
import { ClarkeServiceCardProps } from '@components/molecules/clarke-service-card';
import EnergyPricesSection from '@components/molecules/energy-prices-section';
import ClarkeTechnologySection from '@components/molecules/clarke-technology-section';
import ProductsCardsSection from '@components/templates/products-cards-section/products-cards-section';
import ButtonComponent from '@components/atoms/button/button';
import ModalComponent from '@components/atoms/modal/modal';
import FormComponent from '@components/atoms/form';
import { firstServicesCardSolutions, secondServicesCardSolutions } from '@components/utils/helper';
import MetaTags from '@components/atoms/meta-tags';

import heroImage3 from '@assets/hero-section-3.png';

import style from './style.module.css';

function OurSolutionsPage() {
  const isSmallScreen = useScreenSize();
  const [isModalOpen, setModalOpen] = useState(false);

  const heroBannerSection = twMerge(
    isSmallScreen ? style.HeroBannerSectionMobile : style.HeroBannerSectionDesktop,
    'mx-auto',
  );
  const heroBannerTitle = twMerge(
    isSmallScreen ? 'text-heading-xxLarge w-full pt-8 px-4' : 'max-w-[31rem] text-display-small leading-[3rem]',
    style.HeroBannerTitle,
  );
  const heroBannerParagraph = twMerge(
    isSmallScreen ? 'my-[2rem] text-paragraph-medium px-4' : 'mb-6 mt-4 text-heading-xSmall w-[27.25rem]',
    style.HeroBannerParagraph,
  );
  const bannerStyle = twMerge(isSmallScreen ? 'hidden' : 'block', style.BannerStyle);
  const ctaButton = twMerge(
    isSmallScreen ? 'w-full text-left flex justify-between' : 'w-fit text-center',
    style.ctaButton,
  );
  const heroBannerContainer = twMerge(
    isSmallScreen && 'items-start justify-center h-full space-y-4',
    style.HeroBannerContainer,
  );

  const openModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);

  return (
    <section aria-label="Página Nossas soluções">
      <MetaTags
        title="Soluções em Gestão de Energia para o Mercado Livre"
        description="Conheça as soluções da Clarke Energia para o mercado livre: ClarkeVarejo e ClarkeGestão. Migração, contratação e gestão de energia com tecnologia avançada e atendimento especializado."
        canonicalLink="https://clarke.com.br/nossas-solucoes"
      />
      <HeroSection
        imgPath={heroImage3}
        imgAlt="Foto de uma pessoa interagindo com uma lâmpada"
        imgHeight={770}
        imgWidth={780}
        imgExtraClasses={bannerStyle}
        aria-hidden="true"
      >
        <div className={heroBannerSection}>
          {isSmallScreen && (
            <img src={heroImage3} alt="Foto de uma pessoa segurando uma lampada" className={style.HeroMobileImage} />
          )}
          <div className={heroBannerContainer}>
            <div className="z-10 w-full">
              <h1 className={heroBannerTitle}>
                Nossas <br></br> soluções
              </h1>
              <p className={heroBannerParagraph}>
                Conheça os serviços da Clarke Energia e escolha aquele que mais se adequa a sua empresa!
              </p>
              <div className={isSmallScreen ? 'px-4' : undefined}>
                <ButtonComponent
                  icon="ArrowRightIcon"
                  iconPosition="right"
                  iconType="solid"
                  iconStyles="iconPrimary"
                  kind="primary"
                  size="1"
                  label="Quero fazer uma simulação"
                  className={ctaButton}
                  onClick={openModal}
                />
              </div>
            </div>
          </div>
        </div>
      </HeroSection>
      <OurSolutionsSection />
      <ClarkeServicesSection
        title="O que "
        highlightTitleColor="text-brand-primary-60"
        highlightCardTitleColor="text-brand-primary-70"
        bgColor="bg-brand-primary-10"
        cards={firstServicesCardSolutions as ClarkeServiceCardProps[]}
      />
      <ClarkeServicesSection
        title="Como "
        highlightTitleColor="text-brand-primary-60"
        highlightCardTitleColor="text-brand-primary-70"
        bgColor="bg-brand-light-30"
        cards={secondServicesCardSolutions as ClarkeServiceCardProps[]}
      />
      <ProductsCardsSection />
      <EnergyPricesSection />
      <ClarkeTechnologySection />
      <ModalComponent
        title="Calcule seu potencial de economia"
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        className="text-center"
      >
        <FormComponent />
      </ModalComponent>
    </section>
  );
}

export default OurSolutionsPage;
