import styles from './style.module.css';

interface FooterSectionProps {
  worldImage: string;
}

export function FooterSection({ worldImage }: FooterSectionProps) {
  return (
    <div className={styles.footerSectionContainer}>
      <div className={styles.footerSectionContent}>
        <div className={styles.footerSectionTitle}>
          <h1 className={styles.footerSectionTitleH1}>
            Os clientes da Clarke já evitaram a emissão de{' '}
            <span className={styles.footerSectionTitleSpan}>120 mil toneladas</span> de CO²
          </h1>
          <p className={styles.footerSectionParagraph}>
            Caso esse CO² fosse emitido, seriam necessários mais de 700 mil árvores na Mata Atlântica para compensa-lo.
          </p>
          <p className={styles.footerSectionFootNote}>Fonte: SOS Mata Atlântica com dados de Dezembro/24</p>
        </div>
        <div className={styles.footerSectionContainerImage}>
          <img
            className={styles.footerSectionImage}
            src={worldImage}
            alt="Illustration of a globe with a power plug and green leaves"
          />
        </div>
      </div>
    </div>
  );
}
