import { RenderHomeComponent } from '@components/organisms/home';

function HomePage() {
  return (
    <section className="w-full overflow-x-hidden" aria-label="Página Inicial">
      <RenderHomeComponent />
    </section>
  );
}

export default HomePage;
