import React from 'react';
import ButtonComponent, { Variant } from '@components/atoms/button/button';

interface AnalysisButtonProps {
  label: string;
  onClick: () => void;
  kind: Variant;
  iconStyles: Variant;
  stylesButton?: string;
}

export const AnalysisButton: React.FC<AnalysisButtonProps> = ({
  label,
  onClick,
  kind,
  iconStyles,
  stylesButton = '',
}) => {
  return (
    <ButtonComponent
      icon="ArrowRightIcon"
      iconPosition="right"
      iconType="solid"
      iconStyles={iconStyles}
      kind={kind}
      size="4"
      label={label}
      className={`${stylesButton} h-fit py-4 justify-center md:min-w-full lg:flex text-left lg:text-center gap-5 text-nowrap text-paragraph-large font-normal leading-normal`}
      onClick={onClick}
    />
  );
};
