import newImgOne from '@assets/news-section/new-1.png';
import newImgTwo from '@assets/news-section/new-2.png';
import newImgThree from '@assets/news-section/new-3.png';

interface INew {
  title: JSX.Element;
  description: string;
  imageSrc: string;
  link: string;
}

const NewGadget = ({ imageSrc, title, description, link }: INew) => {
  return (
    <div
      className="h-full w-full p-4 pb-10 flex flex-col items-start gap-11 bg-[#F2F2F2] rounded-3xl cursor-pointer"
      onClick={() => window.open(link, '_blank')}
    >
      <img src={imageSrc} className="object-cover w-full" />
      <div className="flex flex-col gap-7 w-full">
        <div className="text-heading-medium font-semibold text-black">{title}</div>
        <p className="text-paragraph-small font-normal text-black">{description}</p>
      </div>
    </div>
  );
};
const NewsSection = () => {
  const newsIndexer: Array<INew> = [
    {
      title: (
        <h4>
          <span className="text-brand-primary-60">Energia solar ou Mercado Livre? </span> Saiba o que é melhor para sua
          indústria
        </h4>
      ),
      description:
        'Quando o assunto é economizar nas contas de luz de indústrias, uma dúvida muito comum em relação às estratégias para se atingir esse objetivo é: energia solar ou Mercado Livre?',
      imageSrc: newImgOne,
      link: 'https://blog.clarke.com.br/post/energia-solar-ou-mercado-livre/',
    },
    {
      title: (
        <h4>
          <span className="text-brand-primary-60">Mercado Livre de Energia em 2025: </span> saiba quem pode migrar
        </h4>
      ),
      description:
        'A migração para o Mercado Livre de Energia em 2025 está se tornando uma decisão cada vez mais estratégica para empresas de diferentes setores, oferecendo condições bastante favoráveis para uma gestão energética mais eficiente.',
      imageSrc: newImgTwo,
      link: 'https://blog.clarke.com.br/post/mercado-livre-de-energia-em-2025/',
    },
    {
      title: (
        <h4>
          <span className="text-brand-primary-60">Energisa investe na Clarke Energia </span> para expansão da startup
          com foco na comercialização no mercado livre
        </h4>
      ),
      description: 'O Grupo Energisa adquiriu participação majoritária da Clarke Energia.',
      imageSrc: newImgThree,
      link: 'https://blog.clarke.com.br/post/energisa-investe-na-clarke-energia-para-expansao-da-startup-com-foco-na-comercializacao-no-mercado-livre/',
    },
  ];

  return (
    <section className="w-full px-12 lg:px-28 xl:px-44 2xl:px-56 my-20 gap-10 flex flex-col">
      <h2 className="text-black font-bold text-heading-large">
        Você por dentro de tudo o que acontece no setor elétrico
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full">
        {newsIndexer.map((data, id) => (
          <NewGadget key={`new-gadget-id-${id}`} {...data} />
        ))}
      </div>
    </section>
  );
};

export default NewsSection;
