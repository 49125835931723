import iconEnergy from '@assets/icon-energy.svg';
import { useModalContext } from '@contexts/modalContext';
import { AnalysisButton } from '@components/atoms/home/analysis-button';
import { CardComponent } from '@components/atoms/with-and-without-solution/card-component';
import { cardData } from './helper';
import styles from './style.module.css';

export function WithAndWithoutSolution() {
  const { isModalOpen, setModalOpen } = useModalContext();
  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>A Clarke descomplica o mercado livre de energia para a sua empresa</div>
      <div className={styles.cardsContainer}>
        <CardComponent
          title={<>O Mercado Livre sem a Clarke</>}
          bgColorTitle="bg-[#FF6347]"
          bgColorText="bg-[#2E2E2E]"
          textColorTitle="text-white"
          textColorText="text-white"
          image={[
            {
              src: iconEnergy,
              styles: 'w-10 h-10 xl:w-16 xl:h-16',
              alt: 'Plug de tomada macho conectando em uma tomada fêmea',
            },
          ]}
          heroIconText={{ icon: 'XCircleIcon', type: 'outline', extraClassNames: 'h-6 w-6 text-[#ff6347]' }}
          description={cardData.withoutClarke}
        />
        <CardComponent
          title={<>O Mercado Livre com a Clarke</>}
          bgColorTitle="bg-[#00BF6A]"
          bgColorText="bg-[#e5f8dc]"
          textColorTitle="text-white"
          textColorText="text-black"
          heroIconTitle={{
            icon: 'BoltIcon',
            type: 'solid',
            extraClassNames: 'h-10 w-10 xl:w-16 xl:h-16 text-[#FAFAFA]',
          }}
          heroIconText={{ icon: 'CheckCircleIcon', type: 'outline', extraClassNames: 'h-6 w-6 text-[#008c4c]' }}
          description={cardData.withClarke}
        />
      </div>
      <AnalysisButton
        label="Peça sua análise gratuita"
        onClick={openModal}
        kind="secondary"
        iconStyles="iconSecondary"
        stylesButton="w-[23rem] lg:w-[18.75rem]"
      />
    </div>
  );
}
