import mockupBolt from '@assets/RAIO+FOTO.png';
import { FooterSection } from '@components/atoms/home/conversion-booster/footer-section';
import { HeaderSection } from '@components/atoms/home/conversion-booster/header-section';
import style from './style.module.css';

export function ConversationBoosterContainer() {
  return (
    <section className={style.conversationBoosterSection} aria-label="Conversation Booster">
      <div className={style.conversationBoosterContainer}>
        <div className={style.conversationBoosterContent}>
          <HeaderSection />
          <FooterSection />
          <img
            src={mockupBolt}
            alt="Pessoal sorridente com um raio de energia"
            className={style.conversationBoosterImage}
          />
        </div>
      </div>
    </section>
  );
}
