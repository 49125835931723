import CustomersSection from '@components/molecules/customers-section';
import VideoSection from '@components/molecules/video-section';
import { FreeEnergyMarketSection } from '@components/molecules/free-energy-market-section';
import NewsSection from '@components/molecules/news-section';
import BannerSection from '@components/molecules/banner-section';
import FAQSection from '@components/molecules/faq-section';
import MetaTags from '@components/atoms/meta-tags';
import { HeroContainer } from '@components/molecules/home/hero-container';
import { BigNumbersContainer } from '@components/molecules/home/big-numbers-container';
import { ConversationBoosterContainer } from '@components/molecules/home/conversation-booster-container';
import { PainVsFeatureContainer } from '@components/molecules/home/pain-vs-feature-container';
import { WithAndWithoutSolution } from '@components/molecules/with-and-without-solution';

export function RenderHomeComponent() {
  return (
    <>
      <MetaTags
        title="Economize até 40% na conta de luz da sua empresa"
        description="A Clarke ajuda a sua empresa a economizar na conta de luz com o Mercado Livre de Energia. Compre energia limpa e mais barata com a gente!"
        canonicalLink="https://clarke.com.br/"
      />
      <HeroContainer />
      <CustomersSection />
      <PainVsFeatureContainer />
      <WithAndWithoutSolution />
      <BigNumbersContainer />
      <BannerSection />
      <VideoSection />
      <FreeEnergyMarketSection />
      <NewsSection />
      <ConversationBoosterContainer />
      <FAQSection />
    </>
  );
}
