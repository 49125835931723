// Public Paths
export const HOME_PATH = '/';
export const ABOUT_US_PATH = '/sobre-nos';
export const OUR_SOLUTIONS_PATH = '/nossas-solucoes';
export const OLD_FREE_ENERGY_MARKETPLACE_PATH = '/mercado-livre-de-energia';
export const NEW_FREE_ENERGY_MARKETPLACE_PATH = 'https://blog.clarke.com.br/mercado-livre-de-energia/';
export const BLOG_PATH = 'https://blog.clarke.com.br/';
export const CLIENT_AREA_PATH = 'https://cliente.clarke.com.br';

// 404
export const DOES_NOT_EXIST_PATH = '/does-not-exist';
