import React from 'react';

import { VideoCarousel } from '@components/molecules/video-carousel/video-carousel';
import { videoList } from './helper';

const VideoSection: React.FC = () => {
  return (
    <section role="region" aria-labelledby="video-section-title">
      <VideoCarousel videos={videoList} />
    </section>
  );
};

export default VideoSection;
