import bannerImage from '@assets/application-preview-banner.png';
import { AnalysisButton } from '@components/atoms/home/analysis-button';

import styles from './style.module.css';

const BannerSection = () => {
  return (
    <section className={styles.bannerSection}>
      <div className={styles.bannerContainer}>
        <img
          src={bannerImage}
          alt="Prévia do sistema da Clarke"
          className="block w-full"
          loading="lazy"
          decoding="async"
        />
        <h2 className={styles.bannerTitle}>
          Conte com toda a <span className="text-brand-primary-0">tecnologia</span> da Clarke <br></br>
          para acompanhar a eficiência energética da sua empresa!
        </h2>
        <div className={styles.bannerButton}>
          <AnalysisButton
            label={'Veja uma demonstração'}
            onClick={() => window.open('https://lp.clarke.com.br/demonstracao', '_blank')}
            kind={'secondary'}
            iconStyles={'iconSecondary'}
          />
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
