import treeWhiteIcon from '@assets/tree-white.png';

export const freeEnergyMarketCardData = [
  { icon: 'CurrencyDollarIcon', title: 'Economia de até 40%' },
  {
    icon: 'LockOpenIcon',
    title: 'Liberdade para negociar como quiser',
  },
  {
    svgImageUrl: treeWhiteIcon,
    title: 'Escolha consumir energia 100% renovável',
  },
  {
    icon: 'ArrowPathRoundedSquareIcon',
    title: 'Flexibilidade de contratos',
  },

  {
    icon: 'EyeIcon',
    title: 'Transparência na relação custo x consumo',
  },
];
