import { useEffect } from 'react';
import { useModalContext } from '@contexts/modalContext';
import { AnalysisButton } from '@components/atoms/home/analysis-button';
import style from './style.module.css';

export function HeaderSection() {
  const { isModalOpen, setModalOpen } = useModalContext();
  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };
  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);
  return (
    <div className={style.headerSectionContainer}>
      <div className={style.headerTitle}>Ainda não sabe se a Clarke pode ajudar a sua empresa?</div>
      <div className={style.headerContent}>
        <span className={`${style.textAnalysis} font-light`}>Faça uma análise </span>
        <span className={`${style.textAnalysis} font-bold`}>100% gratuita </span>
        <span className={`${style.textAnalysis} font-light`}>
          com nossos especialistas e descubra se o seu negócio pode migrar Mercado Livre de Energia e qual o melhor
          caminho!
        </span>
      </div>
      <div className={style.analysisButtonContainer}>
        <AnalysisButton
          label="Receber análise sem custo"
          onClick={openModal}
          kind="primary"
          iconStyles="iconPrimaryCustom"
          stylesButton="items-center w-[23rem] lg:w-[18.75rem]"
        />
      </div>
    </div>
  );
}
