import React from 'react';

import styles from './carousel.module.css';

interface Logo {
  src: string;
  alt: string;
  text: JSX.Element;
}

interface CarouselProps {
  logos: Logo[];
}

export const Carousel: React.FC<CarouselProps> = ({ logos }) => {
  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselCards}>
        {[...logos, ...logos].map((logo, index) => (
          <div className={styles.carouselCard} key={index}>
            <img className={styles.carouselCardImage} src={logo.src} alt={logo.alt} loading="lazy" />
            <div>{logo.text}</div>
          </div>
        ))}
        ;
      </div>
    </div>
  );
};
