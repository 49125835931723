import {
  HOME_PATH,
  ABOUT_US_PATH,
  OUR_SOLUTIONS_PATH,
  NEW_FREE_ENERGY_MARKETPLACE_PATH,
  BLOG_PATH,
} from '@routers/constants';

export type PathMap = {
  [key in
    | typeof HOME_PATH
    | typeof ABOUT_US_PATH
    | typeof OUR_SOLUTIONS_PATH
    | typeof NEW_FREE_ENERGY_MARKETPLACE_PATH
    | typeof BLOG_PATH]: string;
};

export const mainContent: PathMap = {
  [HOME_PATH]: '#simulador-de-energia',
  [ABOUT_US_PATH]: '#depoimentos',
  [OUR_SOLUTIONS_PATH]: '#nossas-solucoes',
  [NEW_FREE_ENERGY_MARKETPLACE_PATH]: '#como-funciona',
  [BLOG_PATH]: '#',
};
