import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useModalContext } from '@contexts/modalContext';
import { twMerge } from 'tailwind-merge';

import SkipToContent from '@components/atoms/skip-content/skipToContent';
import ButtonComponent from '@components/atoms/button/button';
import ModalComponent from '@components/atoms/modal/modal';
import FormComponent from '@components/atoms/form';

import {
  HOME_PATH,
  ABOUT_US_PATH,
  OUR_SOLUTIONS_PATH,
  NEW_FREE_ENERGY_MARKETPLACE_PATH,
  BLOG_PATH,
  CLIENT_AREA_PATH,
} from '@routers/constants';

import logoClarkeEnergiaDoubleLine from '@assets/logo-clarke-energia.svg';

import style from './style.module.css';

export default function Navbar() {
  const location = useLocation();
  const previousPath = useRef(location.pathname);
  const [shouldScroll, setShouldScroll] = useState(false);
  const { isModalOpen, setModalOpen } = useModalContext();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (previousPath.current !== location.pathname && shouldScroll) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    previousPath.current = location.pathname;
    setShouldScroll(false);
  }, [location.pathname, location.hash, shouldScroll]);

  const handleLinkClick = (path: string) => {
    if (location.pathname === path) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setShouldScroll(true);
    }
  };

  return (
    <nav className={style.NavbarDesktopContainer} role="navigation">
      <SkipToContent />
      <div className={style.NavbarDesktopContent}>
        <Link
          to="/"
          aria-current={location.pathname === HOME_PATH ? 'page' : undefined}
          onClick={() => handleLinkClick(HOME_PATH)}
        >
          <img src={logoClarkeEnergiaDoubleLine} alt="Logotipo da Clarke Energia" width={116} height={64} />
        </Link>

        <div className={style.NavbarDesktopListWrapper} id="navbar-sticky">
          <ul className={style.NavbarDesktopList}>
            <li>
              <Link
                to={ABOUT_US_PATH}
                className={twMerge('block px-3 py-2', location.pathname === ABOUT_US_PATH && 'font-bold')}
                aria-current={location.pathname === ABOUT_US_PATH ? 'page' : undefined}
                onClick={() => handleLinkClick(ABOUT_US_PATH)}
              >
                Sobre nós
              </Link>
            </li>
            <li>
              <Link
                to={OUR_SOLUTIONS_PATH}
                className={twMerge('block px-3 py-2', location.pathname === OUR_SOLUTIONS_PATH && 'font-bold')}
                aria-current={location.pathname === OUR_SOLUTIONS_PATH ? 'page' : undefined}
                onClick={() => handleLinkClick(OUR_SOLUTIONS_PATH)}
              >
                Nossas soluções
              </Link>
            </li>
            <li>
              <Link
                to={NEW_FREE_ENERGY_MARKETPLACE_PATH}
                className={twMerge(
                  'block px-3 py-2',
                  location.pathname === NEW_FREE_ENERGY_MARKETPLACE_PATH && 'font-bold',
                )}
                aria-current={location.pathname === NEW_FREE_ENERGY_MARKETPLACE_PATH ? 'page' : undefined}
                onClick={() => handleLinkClick(NEW_FREE_ENERGY_MARKETPLACE_PATH)}
              >
                Mercado livre de energia
              </Link>
            </li>

            <li>
              <Link to={BLOG_PATH} className="block px-3 py-2">
                Blog
              </Link>
            </li>
          </ul>
        </div>

        <div className={style.NavbarDesktopButtonWrapper}>
          <a target="_blank" href="https://blog.clarke.com.br/simulador-do-mercado-livre-de-energia/">
            <ButtonComponent
              kind="secondary"
              size="2"
              label="Simulador de economia"
              className="text-paragraph-small px-4 py-2"
            />
          </a>
          <ButtonComponent
            kind="primary"
            size="2"
            label="Área do cliente"
            className="text-paragraph-small px-4 py-2"
            onClick={() => window.open(CLIENT_AREA_PATH, '_blank')}
          />
        </div>
        <ModalComponent title="" isOpen={isModalOpen} setOpen={setModalOpen} className="text-center">
          <FormComponent />
        </ModalComponent>
      </div>
    </nav>
  );
}
