import { LinksContent } from '@components/utils/helper';
import { Link } from 'react-router-dom';
import styles from './style.module.css';

export function FooterSection() {
  const talkToEnergySpecialistLink = LinksContent[0];

  return (
    <div className={styles.footerContainer}>
      <h2 className={styles.footerTitle}>Você já está no Mercado Livre de Energia?</h2>
      <p className={styles.footerDescription}>
        <Link to={talkToEnergySpecialistLink.src} target="_blank" className={styles.footerLink}>
          Fale com um acessor da Clarke
        </Link>{' '}
        e descubra como sua empresa pode ter mais resultados.
      </p>
    </div>
  );
}
