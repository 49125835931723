import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroSection from '@components/molecules/hero-section';
import OurHistorySection from '@components/molecules/our-history-section';
import TestimonialsSection from '@components/molecules/testimonials-section';
import RenewableEnergySection from '@components/molecules/reneweable-energy-section';
import InnovativeCompanySection from '@components/molecules/innovative-company-section';
import LiterOfLightSection from '@components/molecules/liter-of-light-section';
import MetaTags from '@components/atoms/meta-tags';

import style from '@pages/about-us-page/style.module.css';

import heroImage2 from '@assets/hero-section-2.png';
import thumbnailInstitucional from '@assets/thumbnail-video-institucional.png';

import videoInstitucional from '@assets/video/apresentacaoInstitucional.mp4';
import SingleVideoSection from '@components/molecules/video-section/single-video';

function AboutUsPage() {
  const isSmallScreen = useScreenSize();
  const heroBannerSection = twMerge(
    isSmallScreen ? style.HeroBannerSectionMobile : style.HeroBannerSectionDesktop,
    'mx-auto',
  );
  const heroBannerContainer = twMerge(
    isSmallScreen && 'items-start justify-center h-full space-y-4',
    style.HeroBannerContainer,
  );
  const heroBannerTitle = twMerge(
    isSmallScreen ? 'text-heading-xxLarge w-[20rem] pt-8 px-4' : 'w-[26.5rem] text-display-small leading-[4.5rem]',
    style.HeroBannerTitle,
  );
  const heroBannerParagraph = twMerge(
    isSmallScreen ? 'mt-2 text-paragraph-medium px-4' : 'mt-2 text-heading-xSmall w-[27.25rem]',
    style.HeroBannerParagraph,
  );
  const bannerStyle = twMerge(isSmallScreen ? 'hidden' : 'block', style.BannerStyle);

  return (
    <section className="relative overflow-x-hidden" aria-label="Página Sobre nós">
      <MetaTags
        title="Clarke Energia: Inovação e sustentabilidade no Mercado Livre de Energia"
        description="Clarke Energia: somos pioneiros em soluções para o Mercado Livre de Energia. Economia, transparência e sustentabilidade desde 2019. Empresa B certificada, premiada e reconhecida no setor energético."
        canonicalLink="https://clarke.com.br/sobre-nos"
      />
      <HeroSection
        imgPath={heroImage2}
        imgAlt="Foto dos membros da equipe Clarke Energia"
        imgHeight={770}
        imgWidth={780}
        imgExtraClasses={bannerStyle}
        aria-hidden="true"
      >
        <div className={heroBannerSection}>
          {isSmallScreen && (
            <img src={heroImage2} alt="Foto dos membros da equipe Clarke Energia" className={style.HeroMobileImage} />
          )}
          <div className={heroBannerContainer}>
            <h1 className={heroBannerTitle}>Conheça a Clarke Energia</h1>
            <h2 className={heroBannerParagraph}>
              O caminho mais simples e seguro para sua empresa contratar energia limpa e até 40% mais barata.
            </h2>
          </div>
        </div>
      </HeroSection>
      <OurHistorySection />
      <LiterOfLightSection />
      <TestimonialsSection />
      <RenewableEnergySection />
      <InnovativeCompanySection />
      <SingleVideoSection
        videoPath={videoInstitucional}
        videoTitle="Clarke Energia | O Futuro é Livre"
        title="A Clarke acredita na democratização da energia"
        description="Conectamos empresas aos melhores fornecedores de energia do Brasil. Nossa missão é entregar aos empresários o poder da tomada de decisão sobre o consumo de energia elétrica no seu negócio, e fazer com que cada empresa tenha a maior economia possível em sua conta de luz, ganhando orçamento para crescer."
        descriptionMaxWidth="max-w-full"
        thumbnailPath={thumbnailInstitucional}
        uploadDate="2024-08-23"
        duration="PT51S"
      ></SingleVideoSection>

      <div></div>
    </section>
  );
}

export default AboutUsPage;
