import React from 'react';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import styles from './style.module.css';

export interface ICardComponentsProps {
  icon?: IconName;
  svgImageUrl?: string;
  title: string;
}

export const CardComponent: React.FC<ICardComponentsProps> = ({ icon, svgImageUrl, title }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardIconWrapper}>
        <div className={styles.cardIcon}>
          {svgImageUrl ? (
            <img src={svgImageUrl} alt="Ícone de uma árvore" className="w-full h-full" />
          ) : (
            <HeroIcon icon={icon as IconName} type="solid" extraClassNames={styles.icon} />
          )}
        </div>
      </div>
      <div className={styles.cardTitle}>{title}</div>
    </div>
  );
};
