import { PainVsFeatureItem } from '@components/atoms/home/pain-vs-feature/pain-vs-feature-item';
import style from './style.module.css';
import { AnalysisButton } from '@components/atoms/home/analysis-button';
import { useModalContext } from '@contexts/modalContext';

export function PainVsFeatureContainer() {
  const { isModalOpen, setModalOpen } = useModalContext();
  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };
  return (
    <section className={style.painVsFeatureSection}>
      <div className={style.painVsFeatureContent}>
        <div className={style.painVsFeatureHeader}>
          <div>
            <span className={`${style.painVsFeatureTitle} text-[#222222]`}>
              Com a Clarke, o Mercado Livre de Energia é
            </span>
            <span className={`${style.painVsFeatureTitle} text-[#00bf6a]`}> mais fácil</span>
          </div>
          <AnalysisButton
            label="Peça sua análise gratuita"
            onClick={openModal}
            kind="tertiary"
            stylesButton="hidden w-[23rem] lg:w-[18.75rem]"
            iconStyles="iconTertiary"
          />
        </div>
        <div className={style.painVsFeatureItems}>
          <PainVsFeatureItem
            icon="ClipboardDocumentListIcon"
            title="O melhor contrato"
            text=" Tenha acesso a propostas de mais de 70 fornecedores e lhe ajudaremos a escolher a melhor economia para sua
              empresa."
          />
          <PainVsFeatureItem
            icon="ScaleIcon"
            title="Menos burocracia"
            text="Deixe toda a parte burocrática e técnica para o time de clarke, o seu papel vai ser aproveitar a economia."
          />
          <PainVsFeatureItem
            icon="UsersIcon"
            title="Atendimento humano"
            text="Tenha um time de especialistas próximos olhando para o seu negócio e cuidando de cada detalhe.s"
          />
          <PainVsFeatureItem
            icon="PresentationChartLineIcon"
            title="Energia descomplicada"
            text="Com nossa tecnologia a sua empresa receberá relatórios de performance no Mercado Livre de Energia."
          />
        </div>
      </div>
    </section>
  );
}
