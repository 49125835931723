import { Link } from 'react-router-dom';
import heroImage from '@assets/raios full.png';
import HeroIcon from '@components/atoms/hero-icon';
import HeroSection from '@components/molecules/hero-section';
import style from './style.module.css';

export function HeroContainer() {
  return (
    <HeroSection
      imgPath={heroImage}
      imgHeight={706}
      imgWidth={779}
      imgAlt="Fundo de raio verde"
      aria-hidden="true"
      imgExtraClasses={style.bannerStyle}
      isHomePath={true}
    >
      <div className={style.heroBanner}>
        <h1 className={style.heroBannerTitle}>
          Sua empresa <span className="text-brand-primary-0">eficiente</span> no
          <span className="text-brand-primary-0"> mercado livre de energia</span>
        </h1>
        <p className={style.heroBannerParagraph}>
          A Clarke descomplica o mercado livre de energia. Te conectamos às melhores oportunidades e fornecedores,
          transformando custo em economia com excelência em atendimento e tecnologia.
        </p>
        <Link to="/mercado-livre-de-energia" className={style.heroBannerLinkBanner}>
          <span className={style.heroBannerLinkText}>
            Saber mais sobre o Mercado Livre de Energia{' '}
            <HeroIcon type="solid" icon="ArrowRightIcon" className="w-5 h-5 text-white" />
          </span>
        </Link>
      </div>
    </HeroSection>
  );
}
