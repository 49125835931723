import HeroIcon from '@components/atoms/hero-icon';
import React, { useState } from 'react';
import styles from './style.module.css';

interface VideoCarouselProps {
  videos: {
    src: string;
    image: string;
    presentationPartOne: string;
    presentationPartTwo: string;
    presentationPartThree: string;
    presentationPartFour: string;
    presentationPartFive: string;
    description: string;
    history?: string;
    labelLink?: string;
  }[];
}

export const VideoCarousel: React.FC<VideoCarouselProps> = ({ videos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState<'left' | 'right' | null>(null);
  const videoRefs = React.useRef<(HTMLVideoElement | HTMLIFrameElement | null)[]>([]);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
    const currentVideoRef = videoRefs.current[currentIndex];
    if (currentVideoRef instanceof HTMLVideoElement) {
      currentVideoRef.pause();
    }
  };

  const handlePlayClick = (index: number) => {
    if (videoRefs.current[currentIndex]) {
      videoRefs.current[currentIndex].src = '';
    }

    setIsVideoPlaying(false);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsVideoPlaying(true);
    }, 100);
  };

  const prevVideo = () => {
    if (isVideoPlaying) {
      handleVideoEnd();
      setIsVideoPlaying(false);
    }
    setDirection('left');
    setCurrentIndex((prev) => (prev - 1 + videos.length) % videos.length);
  };

  const nextVideo = () => {
    if (isVideoPlaying) {
      handleVideoEnd();
      setIsVideoPlaying(false);
    }
    setDirection('right');
    setCurrentIndex((prev) => (prev + 1) % videos.length);
  };

  return (
    <div className={styles.videoCarouselContainer}>
      <div className={styles.videoCarouselContainerVideoAndImage}>
        <div
          onClick={prevVideo}
          className={`${styles.videoCarouselContainerPrevAndNextVideo} left-0 md:left-[10%] xl:left-[20%] 2xl:left-[25%]`}
        >
          <HeroIcon
            name="arrow-left"
            icon="ArrowLeftIcon"
            type="outline"
            extraClassNames={styles.videoCarouselHeroiconLeftAntRight}
          />
        </div>

        <div className={styles.videoCarouselContent}>
          {videos.map((video, index) => {
            const isActive = index === currentIndex;
            const isRight = index === (currentIndex + 1) % videos.length;
            const isLeft = index === (currentIndex - 1 + videos.length) % videos.length;

            const transitionClass =
              (direction === 'left' && isLeft) || (direction === 'right' && isRight) ? '-translate-x-1/2' : '';
            return (
              <div key={index}>
                {(isLeft || isRight || (isActive && !isVideoPlaying)) && (
                  <>
                    <img
                      className={`transition-transform duration-500 ${transitionClass} ${
                        isActive
                          ? `${styles.image} left-1/2 md:w-[43.75rem] md:h-[24.375rem] z-20`
                          : isLeft
                            ? `${styles.image} left-[3.5%] hidden lg:w-[33rem] lg:h-[19rem] xl:block xl:-left-[0%]`
                            : isRight
                              ? `${styles.image} left-[96%] hidden lg:w-[33rem] lg:h-[20rem] xl:block xl:left-[100%]`
                              : 'hidden'
                      }`}
                      src={video.image}
                      alt={`Miniatura do vídeo intitulado '${video.src}'`}
                      onClick={() => handlePlayClick(index)}
                      role="button"
                      tabIndex={0}
                      loading="lazy"
                      decoding="async"
                      aria-label={`Iniciar video: ${video.src}`}
                    />
                    {isActive && (
                      <div
                        role="button"
                        className={styles.videoCarouselContainerPlayVideo}
                        aria-label={`Play ${video.src}`}
                        onClick={() => handlePlayClick(index)}
                      >
                        <HeroIcon type="solid" icon="PlayIcon" extraClassNames={styles.videoCarouselHeroiconPlay} />
                      </div>
                    )}
                  </>
                )}
                {isVideoPlaying && currentIndex === index && (
                  <iframe
                    key={video.src}
                    src={video.src}
                    className={`transition-transform duration-500 ${transitionClass} ${isActive ? `${styles.video}` : 'hidden'}`}
                    allow="autoplay; encrypted-media; picture-in-picture"
                    ref={(el) => {
                      if (el) videoRefs.current[index] = el;
                    }}
                  ></iframe>
                )}
              </div>
            );
          })}
        </div>

        <div
          onClick={nextVideo}
          className={`${styles.videoCarouselContainerPrevAndNextVideo} right-0 md:right-[10%] xl:right-[20%] 2xl:right-[25%]`}
        >
          <HeroIcon
            name="arrow-right"
            icon="ArrowRightIcon"
            type="solid"
            extraClassNames={styles.videoCarouselHeroiconLeftAntRight}
          />
        </div>
      </div>

      <div className={styles.videoCarouselContainerDescription}>
        <span className="text-heading-xSmall md:text-heading-medium text-neutral-50 text-2xl font-light">
          {videos[currentIndex].presentationPartOne}
        </span>
        <span className="text-heading-xSmall md:text-heading-medium text-neutral-50 text-2xl font-bold">
          {videos[currentIndex].presentationPartTwo}
        </span>
        <span className="text-heading-xSmall md:text-heading-medium text-neutral-50 text-2xl font-light">
          {videos[currentIndex].presentationPartThree}
        </span>
        <span className="text-heading-xSmall md:text-heading-medium text-[#005931] bg-green-400 text-2xl font-light">
          {videos[currentIndex].presentationPartFour}
        </span>
        <span className="text-heading-xSmall md:text-heading-medium text-neutral-50 text-2xl font-light">
          {videos[currentIndex].presentationPartFive}
        </span>
      </div>

      <div className={styles.containerDescription}>
        <span className={styles.description}>{videos[currentIndex].description}</span>
        {videos[currentIndex].history && (
          <a href={videos[currentIndex].history} target="_blank" rel="noopener noreferrer" className={styles.link}>
            {videos[currentIndex].labelLink}
          </a>
        )}
      </div>
    </div>
  );
};
