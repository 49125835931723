import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import ButtonComponent from '@components/atoms/button/button';
import { LinksContent } from '@components/utils/helper';

import tabletPreview from '@assets/solution-03.png';

import style from './style.module.css';

const ClarkeTechnologySection = () => {
  const isSmallScreen = useScreenSize();
  const talkToEnergySpecialistLink = LinksContent[0];
  const sectionClarkeTechnology = twMerge(isSmallScreen ? 'px-4 py-8' : 'p-20', style.SectionClarkeTechnology);
  const containerClarkeTechnology = twMerge(isSmallScreen ? 'px-0' : 'px-20', style.ContainerClarkeTechnology);
  const headingClarkeTechnology = twMerge(
    isSmallScreen ? 'mb-8 text-heading-medium text-center ' : 'mb-10 text-heading-xxLarge text-left',
    style.HeadingClarkeTechnology,
  );
  const tabletPreviewImage = twMerge(isSmallScreen ? '-ml-0 mx-auto' : '-ml-4', style.TabletPreviewImage);
  const buttonCTA = twMerge(isSmallScreen ? 'w-full flex justify-between' : 'w-fit', style.ButtonCTA);

  return (
    <section className={sectionClarkeTechnology}>
      <div className={containerClarkeTechnology}>
        {!isSmallScreen && (
          <div>
            <img
              src={tabletPreview}
              alt="Tablet com prévia do sistema da Clarke"
              width="736"
              height="642"
              className={tabletPreviewImage}
              aria-hidden="true"
              loading="lazy"
              decoding="async"
            />
          </div>
        )}
        <div className={isSmallScreen ? 'w-full' : 'w-[29.9375rem]'}>
          <h2 id="clarke-tech-section-title" className={headingClarkeTechnology}>
            Quer saber como as {isSmallScreen && <br />}
            <span className="font-bold text-brand-primary-60">tecnologias da Clarke</span> podem{' '}
            {isSmallScreen && <br />} <span className={style.HighlightClarkeTechnology}>ajudar a sua empresa</span>?
          </h2>
          {isSmallScreen && (
            <div>
              <img
                src={tabletPreview}
                alt="Tablet com prévia do sistema da Clarke"
                width="736"
                height="642"
                className={tabletPreviewImage}
                aria-hidden="true"
                loading="lazy"
                decoding="async"
              />
            </div>
          )}
          <ButtonComponent
            icon="ArrowRightIcon"
            iconPosition="right"
            iconType="solid"
            iconStyles="iconPrimary"
            kind="primary"
            size="1"
            label="Fale com um especialista em energia"
            onClick={() => window.open(talkToEnergySpecialistLink.src, talkToEnergySpecialistLink.typeOfWindow)}
            className={buttonCTA}
          />
        </div>
      </div>
    </section>
  );
};

export default ClarkeTechnologySection;
