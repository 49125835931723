import EduardoCartaxo from '@assets/video/EduardoCartaxo.png';
import BrunoSa from '@assets/video/BrunoSa.png';
import MarceloMarques from '@assets/video/MarceloMarques.png';
import CarlaRosas from '@assets/video/CarlaRosas.png';
import RenanMachado from '@assets/video/RenanMachado.png';
import AlexVenturi from '@assets/video/AlexVenturi.png';
import AlpenPark from '@assets/video/AlpenPark.png';

interface IVideoItem {
  src: string;
  image: string;
  presentationPartOne: string;
  presentationPartTwo: string;
  presentationPartThree: string;
  presentationPartFour: string;
  presentationPartFive: string;
  description: string;
  history?: string;
  labelLink?: string;
}

export const videoList: IVideoItem[] = [
  {
    src: 'https://www.youtube.com/embed/RdWnXmDWd1M?si=raKsG1U9XCFM1f-z&amp;controls=0&autoplay=1',
    image: EduardoCartaxo,
    presentationPartOne: 'Eduardo Cartaxo, Sócio da ',
    presentationPartTwo: 'Polpa de Fruta Graciosa,',
    presentationPartThree: ' conseguiu uma ',
    presentationPartFour: 'economia de 33%',
    presentationPartFive: ' na conta de luz.',
    description:
      '“A Clarke trouxe o Mercado Livre para a viabilidade do empreendedor. No contrato com a fornecedora e migração, em tudo, foi o agente facilitador do processo”. ',
    history:
      'https://blog.clarke.com.br/post/como-a-polpa-de-fruta-graciosa-conseguiu-economizar-na-conta-de-luz-sem-comprometer-a-qualidade/',
    labelLink: 'Conheça nossa história',
  },
  {
    src: 'https://www.youtube.com/embed/WMwODhiJN5Q?si=psqGZoAX_EvXUbbY&amp;controls=0&autoplay=1',
    image: BrunoSa,
    presentationPartOne: 'Para Bruno Sá, ',
    presentationPartTwo: 'da Conspiração Filmes',
    presentationPartThree: ', o interesse em ',
    presentationPartFour: 'energia limpa',
    presentationPartFive: '  era superior ao da economia.',
    description:
      '“O desenho apresentado para a gente foi claro, transparente. A gente teve a segurança que seria atendido 100% do tempo”. ',
    history: 'https://blog.clarke.com.br/post/conspiracao-filmes/',
    labelLink: 'Conheça nossa história',
  },
  {
    src: 'https://www.youtube.com/embed/Vcg3JSSdikI?si=psqGZoAX_EvXUbbY&amp;controls=0&autoplay=1',
    image: MarceloMarques,
    presentationPartOne: 'Marcelo Marques, CFO e Co-fundador da , ',
    presentationPartTwo: 'Mobly',
    presentationPartThree: ', foi um dos ',
    presentationPartFour: 'primeiros clientes',
    presentationPartFive: ' da Clarke no Mercado Livre.',
    description:
      '“Desejo a todo mundo que está vendo esse vídeo se faça a pergunta: será que a Clarke não pode me ajudar a gastar menos e ser mais sustentável?”. ',
  },
  {
    src: 'https://drive.google.com/file/d/11BYNBYlMSZudDAe3hcWJH3GFzXFNhUWN/preview',
    image: CarlaRosas,
    presentationPartOne: 'Carla Rosas, diretora da Le Cordon Bleu Brasil, tinha a energia elétrica como um dos cinco ',
    presentationPartTwo: '',
    presentationPartThree: '',
    presentationPartFour: 'maiores custos.',
    presentationPartFive: '',
    description:
      '“Essa redução de custos também nos torna mais competitivo em termos de flexibilidade na definição dos preços de nossos produtos e cursos. Esperamos que mais empresas sigam esse caminho”. ',
  },
  {
    src: 'https://www.youtube.com/embed/YVssWHwCVKM?si=T8HJffAa72qODqwo&amp;controls=0&autoplay=1',
    image: RenanMachado,
    presentationPartOne: 'Renan Machado, sócio da ',
    presentationPartTwo: 'Nova Aurora',
    presentationPartThree: ',  realizou a migração para reduzir custos e ',
    presentationPartFour: 'expandir seu negócio',
    presentationPartFive: ' da Clarke no Mercado Livre.',
    description:
      '“Desejo a todo mundo que está vendo esse vídeo se faça a pergunta: será que a Clarke não pode me ajudar a gastar menos e ser mais sustentável?”. ',
  },
  {
    src: 'https://www.youtube.com/embed/mq7p555Qajo?si=HbYrzHX6CmvelXou&amp;controls=0&autoplay=1',
    image: AlexVenturi,
    presentationPartOne: 'Alex Venturi, sócio da ',
    presentationPartTwo: 'Champs Fitness',
    presentationPartThree: ', viu a conta de luz ',
    presentationPartFour: 'despencar',
    presentationPartFive: ' de um mês para outro.',
    description:
      '“O meu mérito foi em fazer a contratação. Todo o resto foi feito pela Clarke. Eu recomendaria a Clarke para ser o seu parceiro na decisão de ingressar no Mercado de Energia Livre”. ',
  },
  {
    src: 'https://www.youtube.com/embed/7K2lFFxyxps?si=vi1QLfcDvQFAVU3D&amp;controls=0&autoplay=1',
    image: AlpenPark,
    presentationPartOne: 'Renato Fensterseifer Junior, diretor do ',
    presentationPartTwo: 'Alpen Park',
    presentationPartThree: ', ',
    presentationPartFour: 'economiza em média 30%',
    presentationPartFive: ' na conta de luz.',
    description:
      '“Foi bem importante ter o auxílio da Clarke nos auxiliando na migração,  porque são várias etapas, algumas etapas relativamente complicadas e com alguns detalhes técnicos que seriam bem difíceis fazê-los sem o auxílio de alguém que tem conhecimento no setor”. ',
  },
];
