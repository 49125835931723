import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Helmet } from 'react-helmet';
import config from '@src/config';
import { contactData } from '@utils/helper';
import { useScreenSize } from '@hooks/useScreenSize';

import logoClarkeEnergiaDoubleLine from '@assets/logo-clarke-energia.svg';
import iconLinkedinSVGImg from '@assets/icon_linkedin.svg';
import iconFacebookSVGImg from '@assets/icon_facebook.svg';
import iconInstagramSVGImg from '@assets/icon_instagram.svg';
import iconYoutubeSVGImg from '@assets/icon_youtube.svg';
import topStartupsImage from '@assets/top-100-startups-brasil-2023.png';
import empresaCertificadaImage from '@assets/empresa-certificada.svg';
import logoEnergisa from '@assets/logo-energisa.png';
import logoCCEE from '@assets/logo-CCEE.png';

import { LinksFooter } from '@components/utils/helper';

import {
  HOME_PATH,
  ABOUT_US_PATH,
  OUR_SOLUTIONS_PATH,
  BLOG_PATH,
  CLIENT_AREA_PATH,
  NEW_FREE_ENERGY_MARKETPLACE_PATH,
} from '@routers/constants';

import style from './style.module.css';

interface FooterProps {
  idPage: string;
}

const Footer: React.FC<FooterProps> = ({ idPage }) => {
  const isSmallScreen = useScreenSize();
  const linkLinkedin = LinksFooter[0];
  const linkFacebook = LinksFooter[1];
  const linkInstagram = LinksFooter[2];
  const linkYoutube = LinksFooter[3];
  const linkVacancies = LinksFooter[4];
  const linkAddress = LinksFooter[5];

  const FooterContainer = twMerge(
    style.FooterContainer,
    isSmallScreen ? 'flex-col px-4 pb-[4.5rem] pt-12' : 'flex-row p-20 px-10 gap-5',
  );
  const FooterNavList = twMerge(style.FooterNavList, isSmallScreen ? 'flex-col gap-[2rem]' : 'gap-14');
  const FooterNavListItemCustom = twMerge(style.FooterNavListItemCustom, isSmallScreen ? 'pt-[2.9375rem]' : 'pt-0');
  const FooterNavListItemContent = twMerge(
    style.FooterNavListItemContent,
    isSmallScreen ? '' : 'mx-auto cursor-pointer',
  );

  const achievementsImages = twMerge('flex', isSmallScreen ? 'flex-col' : 'flex-row items-center gap-4');

  return (
    <footer id={idPage} className={style.FooterSection}>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(contactData)}</script>
      </Helmet>
      <div className={FooterContainer}>
        <div className={isSmallScreen ? 'max-w-[15.5rem]' : 'min-w-44 max-w-[15.5rem]'}>
          <a href={HOME_PATH}>
            <img
              src={logoClarkeEnergiaDoubleLine}
              alt="Logotipo da Clarke Energia"
              width={116}
              height={64}
              loading="lazy"
              decoding="async"
            />
          </a>
          <div className='flex items-center gap-2'>
            <p className='text-brand-primary-60 font-Poppins'>Uma empresa do grupo </p>
            <img src={logoEnergisa} alt="logo da Energisa" />
          </div>
          <a href={linkAddress.src} target={linkAddress.typeOfWindow} className="pt-4 text-brand-gray-50">
            Av. Paulista, 1374 - Bela Vista, São Paulo - SP, 01310-100
            <br />
            CNPJ: {config.CLARKE_COMPANY_CNPJ}
          </a>
          <ul className={style.FooterSocialsList}>
            <li>
              <a
                href={linkLinkedin.src}
                target={linkLinkedin.typeOfWindow}
                rel="noopener noreferrer nofollow"
                aria-label="Acessar o LinkedIn da Clarke"
              >
                <img
                  src={iconLinkedinSVGImg}
                  alt="LinkedIn da Clarke"
                  width={24}
                  height={24}
                  role="link"
                  loading="lazy"
                  decoding="async"
                />
              </a>
            </li>
            <li>
              <a
                href={linkFacebook.src}
                target={linkFacebook.typeOfWindow}
                rel="noopener noreferrer nofollow"
                aria-label="Acessar o Facebook da Clarke"
              >
                <img
                  src={iconFacebookSVGImg}
                  alt="Facebook da Clarke"
                  width={24}
                  height={24}
                  role="link"
                  loading="lazy"
                  decoding="async"
                />
              </a>
            </li>
            <li>
              <a
                href={linkInstagram.src}
                target={linkInstagram.typeOfWindow}
                rel="noopener noreferrer nofollow"
                aria-label="Acessar o Instagram da Clarke"
              >
                <img
                  src={iconInstagramSVGImg}
                  alt="Instagram da Clarke"
                  width={24}
                  height={24}
                  role="link"
                  loading="lazy"
                  decoding="async"
                />
              </a>
            </li>
            <li>
              <a
                href={linkYoutube.src}
                target={linkYoutube.typeOfWindow}
                rel="noopener noreferrer nofollow"
                aria-label="Acessar o YouTube da Clarke"
              >
                <img
                  src={iconYoutubeSVGImg}
                  alt="YouTube da Clarke"
                  width={34}
                  height={24}
                  role="link"
                  loading="lazy"
                  decoding="async"
                />
              </a>
            </li>
          </ul>
        </div>
        <nav className={FooterNavList} role="navigation">
          <ul className={twMerge(isSmallScreen && 'pt-10 -mb-10 h-fit')}>
            <li>
              <h2 id="fale-conosco-section" className={twMerge(style.FooterNavListItemTitle, 'min-w-[6.25rem]')}>
                Fale conosco
              </h2>
            </li>
            <li>
              <a
                href="https://wa.me/+15557005576"
                className={FooterNavListItemContent}
                target="_blank"
                rel="noopener noreferrer"
                aria-labelledby="fale-conosco-section"
              >
                WhatsApp
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <h2
                id="empresa-section"
                className={twMerge(style.FooterNavListItemTitle, FooterNavListItemCustom, 'min-w-[5.875rem]')}
              >
                A empresa
              </h2>
            </li>
            <li>
              <a
                href={ABOUT_US_PATH}
                target="_blank"
                className={style.FooterNavListItemContent}
                aria-labelledby="empresa-section"
              >
                Sobre nós
              </a>
            </li>
            <li>
              <a
                href={linkVacancies.src}
                target={linkVacancies.typeOfWindow}
                className={style.FooterNavListItemContent}
                aria-labelledby="empresa-section"
              >
                Vagas
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <h2 id="solucoes-section" className={twMerge(style.FooterNavListItemTitle, 'min-w-[11.3rem]')}>
                Nossas soluções
              </h2>
            </li>
            <li>
              <a
                href={OUR_SOLUTIONS_PATH}
                className={style.FooterNavListItemContent}
                target="_blank"
                aria-labelledby="solucoes-section"
              >
                Conheça as soluções
              </a>
            </li>
            <li>
              <a
                href={CLIENT_AREA_PATH}
                target="_blank"
                className={style.FooterNavListItemContent}
                aria-labelledby="solucoes-section"
              >
                Acesse a Área do Cliente
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <h2 id="mercado-livre-section" className={twMerge(style.FooterNavListItemTitle, 'min-w-[6.25rem]')}>
                Mercado livre
              </h2>
            </li>
            <li>
              <a
                href={NEW_FREE_ENERGY_MARKETPLACE_PATH}
                className={style.FooterNavListItemContent}
                target="_blank"
                aria-labelledby="mercado-livre-section"
              >
                Saiba mais
              </a>
            </li>
            <li>
              <a
                href={BLOG_PATH}
                target="_blank"
                className={style.FooterNavListItemContent}
                aria-labelledby="mercado-livre-section"
              >
                Blog
              </a>
            </li>
          </ul>
          <div className="min-w-[13.942rem]">
            <h2 className={style.FooterNavListItemTitle}>Prêmios</h2>
            <ul className={achievementsImages}>
              <li>
                <img
                  src={topStartupsImage}
                  alt="TOP startups Brasil 2023"
                  width={80}
                  height={40}
                  className={twMerge(FooterNavListItemContent, 'w-[5rem] h-[2.5rem]')}
                  aria-describedby="top-startups-label"
                  loading="lazy"
                  decoding="async"
                />
                <p id="top-startups-label" className="hidden">
                  Selo de premiação: Top 100 Open Startups - Nas categorias EnergyTech e Geral
                </p>
              </li>
              <li>
                <img
                  src={empresaCertificadaImage}
                  alt="Selo do CCEE - Empresa certificada 2024"
                  width={41}
                  height={70}
                  className={twMerge(FooterNavListItemContent, 'w-[2.5625rem] h-[4.375rem]')}
                  aria-describedby="empresa-certificada-label"
                  loading="lazy"
                  decoding="async"
                />
                <p id="empresa-certificada-label" className="hidden">
                  Selo de premiação: Empresa B - Certificação de impacto socioambiental
                </p>
              </li>
              <li>
                <img
                  src={logoCCEE}
                  alt="Selo da premiação"
                  width={70}
                  height={70}
                  className={twMerge(FooterNavListItemContent, 'w-[4.375rem] h-[4.375rem]')}
                  aria-describedby="selo-ccee-label"
                  loading="lazy"
                  decoding="async"
                />
                <p id="selo-ccee-label" className="hidden">
                  Selo de premiação: Empresa B - Certificação de impacto socioambiental
                </p>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
