import styles from './style.module.css';
interface InfoCardProps {
  value: string;
  text: string;
  tagClass?: Record<string, string>;
}

export function InfoCard({ value, text, tagClass }: InfoCardProps) {
  return (
    <div className={styles.infoCardContainer}>
      <strong className={`${tagClass?.strong} ${styles.infoCardStrong}`}>+{value}</strong>
      <p className={`${tagClass?.paragraph} ${styles.infoCardParagraph}`}>{text}</p>
    </div>
  );
}
