import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import styles from './style.module.css';

interface CardComponentProps {
  title: JSX.Element | string;
  bgColorTitle: string;
  bgColorText: string;
  textColorTitle: string;
  textColorText: string;
  image?: { src: string; styles: string; alt?: string }[];

  heroIconTitle?: { icon: IconName; type: 'outline' | 'solid'; extraClassNames: string };
  heroIconText: { icon: IconName; type: 'outline' | 'solid'; extraClassNames: string };
  description: string[];
}

export const CardComponent = ({
  title,
  bgColorTitle,
  bgColorText,
  textColorTitle,
  textColorText,
  image,
  heroIconTitle,
  heroIconText,
  description,
}: CardComponentProps): JSX.Element => {
  return (
    <div className={`${styles.cardContainer} ${bgColorText} `}>
      <div className={`${styles.cardContent} ${bgColorTitle}`}>
        {image && (
          <div>
            {image?.map((img, index) => (
              <img key={index} className={`${img.styles}`} src={img.src} alt={img.alt || 'Image'} />
            ))}
          </div>
        )}
        {heroIconTitle && (
          <HeroIcon
            icon={heroIconTitle?.icon}
            type={heroIconTitle?.type}
            extraClassNames={heroIconTitle?.extraClassNames}
          />
        )}
        <div className={`${styles.cardTitle} ${textColorTitle}`}>{title}</div>
      </div>
      <div className={styles.cardDescriptionContainer}>
        {description.map((desc, index) => (
          <div key={index} className={styles.cardDescriptionContent}>
            <div>
              <HeroIcon
                icon={heroIconText.icon}
                type={heroIconText.type}
                extraClassNames={heroIconText.extraClassNames}
              />
            </div>
            <div className={`${styles.cardDescriptionText} ${textColorText}`}>{desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
