import { useModalContext } from '@contexts/modalContext';

import { CardComponent } from '@components/atoms/home/free-energy-market-section/card-component';
import { IconName } from '@components/atoms/hero-icon';

import { freeEnergyMarketCardData } from './helper';
import style from './style.module.css';
import { AnalysisButton } from '@components/atoms/home/analysis-button';

export const FreeEnergyMarketSection = () => {
  const { isModalOpen, setModalOpen } = useModalContext();

  const openModal = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };

  return (
    <section className={style.freeMarketSection}>
      <div className={style.freeMarketHeader}>
        <span className={`text-neutral-50 ${style.freeMarketHeaderSpan}`}>Por que o </span>
        <span className={`text-[#00bf6a] ${style.freeMarketHeaderSpan}`}> Mercado Livre </span>
        <span className={`text-neutral-50 ${style.freeMarketHeaderSpan}`}> de Energia? </span>
      </div>
      <div className={style.freeMarketCardsGroup}>
        {freeEnergyMarketCardData.map((cardData, idx) => (
          <CardComponent
            key={idx}
            icon={cardData.icon as IconName}
            svgImageUrl={cardData.svgImageUrl}
            title={cardData.title}
          />
        ))}
        <div className={style.freeMarketFooter}>
          <div className={style.freeMarketFooterText}>
            Ainda não conhece o Mercado Livre de Energia e quer entender melhor?
          </div>
          <div className="w-full">
            <AnalysisButton
              label="Saiba mais"
              onClick={openModal}
              kind="primaryCustom"
              iconStyles="iconPrimaryCustom"
              stylesButton="w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
