import React from 'react';

import { logosCarousel } from '@components/utils/helper';
import { Carousel } from '@components/molecules/carousel/index';

import styles from './style.module.css';

const CustomersSection: React.FC = () => {
  return (
    <section className={styles.customerSection} aria-labelledby="customers-section-heading">
      <div className={styles.customerContainer}>
        <p id="customers-section-heading" className={styles.customerHeader}>
          Somos uma gestora de energia aprovada por
          <span className="text-brand-primary-60"> líderes </span>
          do mercado
        </p>
        <Carousel logos={logosCarousel} />
      </div>
    </section>
  );
};

export default CustomersSection;
