import worldImage from '@assets/rb_2451 1.png';
import { InfoCard } from '@components/atoms/home/big-numbers/info-card';
import { FooterSection } from '@components/atoms/home/big-numbers/footer-section';

import styles from './style.module.css';
export function BigNumbersContainer() {
  const data = { companies: '700 empresas', moneySaved: 'R$30 milhões', suppliers: '75 fornecedores' };

  return (
    <section className={styles.bigNumbersContainer} aria-label="Big Numbers">
      <div className={styles.bigNumbersTitle}>
        <span className={`${styles.bigNumbersTitleText} text-[#222222]`}>A Clarke Energia é a </span>
        <span className={`${styles.bigNumbersTitleText} text-[#00bf6a]`}>maior gestora independente de energia</span>
        <span className={`${styles.bigNumbersTitleText} text-[#222222]`}> do Brasil</span>
      </div>

      <div className={styles.bigNumbersCards}>
        <InfoCard value={data.companies} text="confiam na Clarke" />
        <InfoCard value={data.moneySaved} text="economizados por empresas através da Clarke Energia" />
        <InfoCard value={data.suppliers} text="competindo em nosso marketplace para atender os clientes da Clarke" />
      </div>
      <FooterSection worldImage={worldImage} />
    </section>
  );
}
