import { FormComponentHeroSection } from '@components/atoms/home/form-hero';
import style from './style.module.css';

export function HeroModalComponent() {
  return (
    <div className={style.heroModalContainer}>
      <div className={`${style.customScrollbar} h-full overflow-auto`}>
        <FormComponentHeroSection formStyle={style.formStyle} />
      </div>
    </div>
  );
}
